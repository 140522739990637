import { DateTime } from 'luxon'
import { ComposerTranslation } from 'vue-i18n'
import { McDiaComponent, McDiaMessageStatus, McDiaMessageStatusType } from '~/lib/models/mcdia'
import { AdvanceSalesMessages } from '~/lib/services/store/country/country.dtos'

export function transformDateToLocaleDate(date: string) {
  return DateTime.fromISO(date, { zone: 'utc' }).toFormat('dd/MM/y')
}

/**
 * Check date and format it to
 * Today - HH:MM, Yesterday - HH:MM or DD/MM - HH:MM
 *
 * @param string
 * @returns string
 */
export function transformOrderDate(date: string) {
  const { t } = useLocale()
  const orderDate = new Date(date)
  const actualDay = new Date()
  const yesterday = new Date()
  yesterday.setDate(actualDay.getDate() - 1)

  const timeTwoDigit = (number: number) => ('0' + number).slice(-2)
  const hour = timeTwoDigit(orderDate.getHours())
  const minutes = timeTwoDigit(orderDate.getMinutes())

  const isToday = actualDay.toDateString() === orderDate.toDateString()
  const isYesterday = yesterday.toDateString() === orderDate.toDateString()

  if (isToday) {
    return `${t('orders.date.today')} - ${hour}:${minutes}`
  } else if (isYesterday) {
    return `${t('orders.date.yesterday')} - ${hour}:${minutes}`
  } else {
    return `${orderDate.toLocaleDateString()} - ${hour}:${minutes}`
  }
}

/**
 * Transform date to yyyy-MM-dd'T'HH:mm:ss'Z' format
 * We need this format to send the date to the backend
 * @param date
 */
export function birthdayValidator(date: Date) {
  const dt = DateTime.fromJSDate(date, { zone: 'utc' })
  const { day, month, year } = dt
  const dtBuilded = DateTime.fromObject(
    { day, month, year, hour: 0, minute: 0, second: 0, millisecond: 0 },
    { zone: 'utc' }
  )
  const formatted = `${dtBuilded.toFormat('y-MM-dd')}T${dtBuilded.toFormat('HH:mm:ss')}Z`
  return formatted
}

/**
 * Check if the user is over minAge years old
 * @param date
 * @returns boolean
 */
export function checkAge(date: Date, minAge: string) {
  const minimumAge = parseInt(minAge)
  if (Number.isNaN(minimumAge) || minimumAge <= 0) return true
  const today = new Date()
  const birthDate = new Date(date)
  let age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  if (age < minimumAge) return false

  return true
}

export const getMcDiaMessageStatus = (mcdiaDates: {
  dateFrom: string
  dateTo: string
}): McDiaMessageStatusType => {
  const { dateFrom, dateTo } = mcdiaDates
  // check date, is before or is after
  const now = DateTime.utc()

  const dateFromDatetime = DateTime.fromISO(dateFrom, { zone: 'utc' })
  const dateToDatetime = DateTime.fromISO(dateTo, { zone: 'utc' })

  const todayResetTime = now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

  // is on range of mcdia
  if (
    todayResetTime.toMillis() >= dateFromDatetime.toMillis() &&
    todayResetTime.toMillis() <= dateToDatetime.toMillis()
  )
    return McDiaMessageStatus.ON_DATE
  // is expired
  else if (todayResetTime.toMillis() > dateToDatetime.toMillis())
    return McDiaMessageStatus.AFTER_DATE
  // is advance sale
  else return McDiaMessageStatus.ADVANCE_SALE
}

export const getMcDiaMessageComponent = ({
  component = McDiaComponent.CART,
  messages,
  mcdiaDates,
}: {
  component: string
  messages: AdvanceSalesMessages
  mcdiaDates: { dateFrom: string; dateTo: string }
}): string => {
  const status = getMcDiaMessageStatus(mcdiaDates)

  const isAdvanceSale = status === McDiaMessageStatus.ADVANCE_SALE
  const isOnDate = status === McDiaMessageStatus.ON_DATE

  let message = ''
  switch (component) {
    case McDiaComponent.CART: {
      const cartMessages = messages.cart
      if (isOnDate) message = cartMessages.inDate
      else if (isAdvanceSale) message = cartMessages.beforeDate
      break
    }
    case McDiaComponent.ORDER_DETAIL: {
      const orderDetailMessages = messages.orderDetail
      if (isOnDate) message = orderDetailMessages.inDate
      else if (isAdvanceSale) message = orderDetailMessages.beforeDate
      else message = orderDetailMessages.outDate
      break
    }
    case McDiaComponent.PAYMENT: {
      const paymentMessages = messages.payment
      if (isOnDate) message = paymentMessages.inDate
      else if (isAdvanceSale) message = paymentMessages.beforeDate
      break
    }
  }

  const messageWithBreaklines = message.replace(/\s{2}/gm, '<br/>')
  return messageWithBreaklines
}

/* Countdown */
export const formatCountdown = (
  { hours, minutes, seconds }: { hours: number; minutes: number; seconds: number },
  t: ComposerTranslation
) => {
  if (hours) {
    const isManyHours = hours > 1
    const unitHours = isManyHours ? t('common.time.hours') : t('common.time.hour')
    if (minutes > 0) {
      const unitMinutes = minutes > 1 ? t('common.time.minutes') : t('common.time.minute')
      return `${hours} ${unitHours} ${t('common.and')} ${minutes} ${unitMinutes}`
    }
    return `${hours} ${unitHours}`
  } else if (minutes) {
    const isMany = minutes > 1
    const unit = isMany ? t('common.time.minutes') : t('common.time.minute')
    if (!isMany) {
      const unitSeconds = seconds > 1 ? t('common.time.seconds') : t('common.time.second')
      return `${minutes} ${unit} ${t('common.and')} ${seconds} ${unitSeconds}`
    }
    return `${minutes} ${unit}`
  }
  const isMany = seconds > 1
  const unit = isMany ? t('common.time.seconds') : t('common.time.second')
  return `${seconds} ${unit}`
}

const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
]

export const timeAgoFromDate = (dateTime: DateTime, lang: string, countryCode: string) => {
  const mainLang = lang.split('_')[0]
  const locale = `${mainLang}-${countryCode}`
  const diff = dateTime.diffNow().shiftTo(...units)
  const unit = units.find(unit => diff.get(unit) !== 0) || 'second'
  const relativeFormatter = new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
  })
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
}
