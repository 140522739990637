export const McDiaComponent = {
  CART: 'cart',
  ORDER_DETAIL: 'orderDetail',
  PAYMENT: 'payment',
} as const

export const McDiaMessageStatus = {
  ADVANCE_SALE: 'ADVANCE_SALE',
  ON_DATE: 'ON_DATE',
  AFTER_DATE: 'AFTER_DATE',
} as const

export type McDiaMessageStatusType = (typeof McDiaMessageStatus)[keyof typeof McDiaMessageStatus]

export type McDiaProductDetail = {
  title: string
  content: string
  linkText: string
  linkUrl: string
}
